import { createStore } from 'vuex'
import IDCore from 'id-js'
import UtilitiesCore from 'utilities-js'
import Cookies from 'js-cookie'
import { ProfileCore } from 'profile-ts'
import { log as logFS } from '@fullstory/browser'

import role from './modules/role'
import whitelabel from './modules/whitelabel'
import modal from './modules/modal'
import captcha from './modules/captcha'
import mfa from './modules/mfa'

import { version } from '@/../package.json'
import redirect from '@/store/modules/redirect'
import { dataCore, dataCoreProfile } from '@/utils/dataCores'
import Analitica from '@/vendor/analitica.vendor'

export const IDInstance = new IDCore(dataCore)
IDInstance.setCallbackError(dataCoreProfile.setCallbackError)

export const UtilitiesInstance = new UtilitiesCore(dataCore)
export const ProfileInstance = new ProfileCore(dataCoreProfile)

export const env = UtilitiesInstance.envAcronym

const store = createStore({
  modules: {
    role,
    whitelabel,
    modal,
    redirect,
    captcha,
    mfa
  },
  state () {
    return {
      fpHeaders: null,
      domain: IDInstance.domain,
      user: {
        email: '',
        invites: {
          items: [],
          pagination: {}
        }
      },
      globalLoading: false,
      globalError: false,
      maintenance: false,
      globalErrorCode: '',
      feedbackType: '',
      validated: {
        value: {},
        type: undefined
      },
      timezones: [],
      username: null,
      pref: ProfileInstance.me.getPreferences(),
      appVersion: version,
      showTermsOfUseModal: false,
      unificationMessage: false,
      twoFaMethod: null
    }
  },
  mutations: {
    SET_FP_HEADERS (state, headers) {
      state.user.fpHeaders = headers
    },
    SET_GLOBAL_ERROR (state, { status, code }) {
      state.globalError = status
      state.globalErrorCode = code
      if (status && code !== 404 && code !== 403)
        logFS('error', 'Error: redirected to screen 500')
    },
    SET_USER_EMAIL (state, email) {
      state.user.email = email
    },
    SET_INVITES (state, invites) {
      state.user.invites = invites
    },
    SET_SIGN_LOADING (state, loaderState) {
      state.globalLoading = loaderState
    },
    SET_FEEDBACK_TYPE (state, payload) {
      state.feedbackType = payload
    },
    SET_VALIDATED (state, payload) {
      state.validated = payload
    },
    SET_TIMEZONES (state, payload) {
      state.timezones = payload
    },
    SET_USERNAME (state, username) {
      state.username = username
    },
    SET_PREF (state, pref) {
      state.pref = { ...state.pref, ...pref }
    },
    SET_UNIFICATION_MESSAGE (state, value) {
      state.unificationMessage = value
    },
    SET_REQUIRED_CAPTCHA_V2 (state, value) {
      state.requiredCaptchav2 = value
    },
    SET_TERMS_OF_USE_MODAL (state, value) {
      state.showTermsOfUseModal = value
    },
    SET_TWOFA_METHOD (state, twoFaMethod) {
      state.twoFaMethod = twoFaMethod
    }
  },
  getters: {
    token: () => {
      return IDInstance.user.getToken()
    },
    GET_FEEDBACK_TYPE (state) {
      return state.feedbackType
    },
    GET_VALIDATED (state) {
      return state.validated
    },
    invite: state => {
      return state.user.invites.items[0]
    },
    totalInvites: state => {
      return state.user.invites.pagination.total_items
    },
    pref (state) {
      return state.pref
    },
    appVersion (state) {
      return state.appVersion
    },
    userEmail (state) {
      return state.user.email
    },
    GET_GLOBAL_ERROR_CODE (state) {
      return state.globalErrorCode
    },
    maintenance (state) {
      return state.maintenance
    },
    showTermsOfUseModal (state) {
      return state.showTermsOfUseModal
    },
    unificationMessage (state) {
      return state.unificationMessage
    }
  },
  actions: {
    async CREATE_AUTHORIZATION ({ getters }, data) {
      const versionCaptcha = getters.showCaptchav2 ? 'v2' : 'v3'
      const config = { headers: { 'x-captcha-version': versionCaptcha } }
      return IDInstance.auth.connect.createToken(data, config)
    },
    REMOVE_SESSION_ID () {
      const sessionID = sessionStorage.getItem(`${env}mp_si`)
      if (sessionID) {
        sessionStorage.removeItem(`${env}mp_si`)
      }
    },
    LOGIN ({ dispatch }) {
      dispatch('REMOVE_SESSION_ID')
      dispatch('UPDATE_SIGN_LOADING', true)
      dispatch('GO_TO_REDIRECT_URL')
    },
    PROFILE_AUTHORIZATION () {
      return ProfileInstance.authorization.get(
        {},
        'id',
        process.env.VUE_APP_PROFILE_URL
      )
    },
    CREATE_REGISTER ({ commit }, userData) {
      return IDInstance.operation.register(userData)
    },
    RESET_PASSWORD (context, email) {
      return IDInstance.operation.resetPassword(email)
    },
    UPDATE_SIGN_LOADING ({ commit }, loaderState) {
      commit('SET_SIGN_LOADING', loaderState)
    },
    DELETE_TOKENS () {
      return IDInstance.user.deleteToken()
    },
    SET_PASSWORD (context, body) {
      return IDInstance.operation.setPassword(body)
    },
    UPDATE_FEEDBACK_TYPE ({ commit }, payload) {
      commit('SET_FEEDBACK_TYPE', payload)
    },
    VALIDATE_TOKEN ({ commit }, token) {
      return IDInstance.operation.validateToken(token).then(res => {
        Analitica.updateMetadata(oldMetadata => {
          return {
            ...oldMetadata,
            user: {
              user_email: res.value.email,
              type: res.type,
              origin: 'autocred'
            }
          }
        })

        commit('SET_VALIDATED', res.data || res)
        return Promise.resolve(res)
      })
    },
    CREATE_USER (context, body) {
      return IDInstance.user.create(body)
    },
    GET_TIMEZONES ({ commit }) {
      return UtilitiesInstance.commons.getTimezones().then(res => {
        commit('SET_TIMEZONES', res)
        return res
      })
    },
    VALIDATE_USERNAME ({ commit }, username) {
      return IDInstance.operation.validateUsername(username).then(res => {
        commit('SET_USERNAME', username)
        return Promise.resolve(res)
      })
    },
    GET_PREFERENCES (context) {
      const pref = Cookies.get(`${env}mp_pref`)
        ? JSON.parse(
            atob(Cookies.get(`${env}mp_pref`), { domain: context.state.domain })
          )
        : {}
      context.commit('SET_PREF', pref)
      return pref
    },
    SET_LANGUAGE ({ dispatch, state }, lang) {
      const preferences = { ...state.pref }
      preferences.language = lang
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_THEME_MODE ({ dispatch, state }, mode) {
      const preferences = { ...state.pref }
      preferences.appearance.mode = mode
      dispatch('SET_PREFERENCES', preferences)
    },
    async SET_PREFERENCES (context, pref) {
      try {
        const newPrefs = pref
        newPrefs.appearance.theme = process.env.VUE_APP_THEME
        const encondedPrefs = btoa(JSON.stringify(newPrefs))
        Cookies.set(`${env}mp_pref`, encondedPrefs, {
          domain: context.state.domain
        })
        context.commit('SET_PREF', newPrefs)
        return true
      } catch (_err) {
        console.error(_err)
      }
    },
    FORCE_LIGHT_MODE ({ state, dispatch }) {
      const preferences = { ...state.pref }
      preferences.appearance.mode = 'light'
      dispatch('SET_PREFERENCES', preferences)
    },
    SET_UNIFICATION_MESSAGE ({ commit }, value) {
      commit('SET_UNIFICATION_MESSAGE', value)
    },
    SET_TERMS_OF_USE_MODAL (context) {
      context.commit('SET_TERMS_OF_USE_MODAL')
    },
    UPDATE_TWOFA_METHOD ({ commit }, twoFaMethod) {
      commit('SET_TWOFA_METHOD', twoFaMethod)
    }
  }
})

export default store
